/** @jsxImportSource @emotion/react */
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { CameraScheduleTabPane } from './CameraScheduleTabPane';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { Camera } from '../../../hooks/graphql/camera';
import { Scenario } from '../../../lib/features/scenario';
import { spacing } from '../../../scss/spacing';
import { emptyCameraSchedule } from '../../../typescript/camera/schedule';
import { BWButton } from '../../elements/BWButton';
import { ScenarioLabel } from '../../elements/ScenarioLabel';

interface Props {
  show: boolean;
  onClose: () => void;
}

export function CameraScheduleModal({ show, onClose }: Props) {
  const { theme } = useThemeContext();
  const { gqlCamera, updateCamera, cameraScenarios } = useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);

  const [cameraSchedules, setCameraSchedules] = useState<Camera['schedule']>(
    gqlCamera?.schedule || {},
  );

  const [selectedScenario, setSelectedScenario] = useState<
    Scenario | undefined
  >(cameraScenarios.at(0));

  useEffect(() => {
    if (gqlCamera?.schedule) {
      setCameraSchedules(gqlCamera.schedule);
    }
  }, [gqlCamera?.schedule]);

  const handleSaveChanges = async () => {
    if (gqlCamera) {
      const cameraUpdatePayload = {
        schedule: JSON.stringify(cameraSchedules),
      };

      setLoading(true);
      await updateCamera(cameraUpdatePayload);
      setLoading(false);
    }

    onClose();
  };

  return (
    <Modal
      centered
      className="custom-modal bw-modal"
      contentClassName={theme}
      dialogClassName="modal-40vw"
      show={show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera_schedule.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Tabs defaultActiveKey="default" fill justify>
              <Tab
                eventKey="default"
                title={i18n.t('modal.camera_schedule.default_tab')}
                style={{
                  marginTop: spacing(4),
                }}
              >
                <CameraScheduleTabPane
                  variant="camera_schedule"
                  cameraSchedule={cameraSchedules.cameraSchedule}
                  onScheduleChange={(cameraSchedule) => {
                    setCameraSchedules({
                      ...cameraSchedules,
                      cameraSchedule: cameraSchedule || {
                        ...emptyCameraSchedule,
                      },
                    });
                  }}
                />
              </Tab>
              <Tab
                eventKey="scenario"
                title={i18n.t('modal.camera_schedule.custom_tab')}
                style={{
                  marginTop: spacing(4),
                }}
              >
                <p className="mb-1 weight-600">
                  {i18n.t('modal.camera_schedule.scenarios.title')}
                </p>
                <p className="sub-color">
                  {i18n.t('modal.camera_schedule.scenarios.description')}
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                  }}
                >
                  {cameraScenarios.map((scenario) => (
                    <ScenarioLabel
                      key={scenario.name}
                      scenario={scenario}
                      showTooltip
                      tooltipPlacement="bottom"
                      isHighlighted={
                        selectedScenario
                          ? selectedScenario.name === scenario.name
                          : undefined
                      }
                      fadeOpacity={0.3}
                      onClick={() => setSelectedScenario(scenario)}
                      cursor="pointer"
                    />
                  ))}
                </div>

                {cameraScenarios.map((scenario) =>
                  scenario.name === selectedScenario?.name ? (
                    <Col
                      key={scenario.name}
                      md={12}
                      style={{
                        marginTop: spacing(4),
                      }}
                    >
                      <CameraScheduleTabPane
                        variant="scenario_schedule"
                        cameraSchedule={
                          cameraSchedules.scenarioSchedule?.[
                            selectedScenario.name
                          ]
                        }
                        onScheduleChange={(cameraSchedule) => {
                          if (cameraSchedule) {
                            setCameraSchedules({
                              ...cameraSchedules,
                              scenarioSchedule: {
                                ...cameraSchedules.scenarioSchedule,
                                [selectedScenario.name]: cameraSchedule,
                              },
                            });
                          } else {
                            const {
                              [selectedScenario.name]: _,
                              ...scenarioSchedule
                            } = cameraSchedules.scenarioSchedule || {};

                            setCameraSchedules({
                              ...cameraSchedules,
                              scenarioSchedule,
                            });
                          }
                        }}
                      />
                    </Col>
                  ) : null,
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <BWButton
          title={i18n.t('button.back_to_settings')}
          className="button-with-loader"
          variant="outline"
          type="button"
          disabled={loading}
          onClick={onClose}
        />

        <BWButton
          title={i18n.t('button.save_changes')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={handleSaveChanges}
          loading={loading}
          disabled={loading}
        />
      </Modal.Footer>
    </Modal>
  );
}
