import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, FormCheck, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  getScenarioCategoryTitle,
  groupScenariosByCategory,
  Scenario,
} from '../../../lib/features/scenario';
import { BWButton } from '../../elements/BWButton';
import { ScenarioLabel } from '../../elements/ScenarioLabel';

interface Props {
  show: boolean;
  onClose: () => void;
}

export function CameraScenariosModal({ show, onClose }: Props) {
  const { theme } = useThemeContext();
  const { gqlCamera, updateCamera } = useCameraContext();
  const { getScenariosByCamera, customerScenarios } = useScenarioContext();
  const [loading, setLoading] = useState<boolean>(false);

  const [activeScenarios, setActiveScenarios] = useState<Scenario[]>(
    getScenariosByCamera(gqlCamera),
  );

  useEffect(() => {
    setActiveScenarios(getScenariosByCamera(gqlCamera));
  }, [getScenariosByCamera, gqlCamera]);

  const handleAddScenario = useCallback(
    (scenario: Scenario) => {
      setActiveScenarios([...activeScenarios, scenario]);
    },
    [activeScenarios],
  );

  const handleRemoveScenario = useCallback(
    (scenario: Scenario) => {
      setActiveScenarios(
        activeScenarios.filter((s) => s.name !== scenario.name),
      );
    },
    [activeScenarios],
  );

  const handleScenarioClick = useCallback(
    (scenario: Scenario) => {
      if (activeScenarios.includes(scenario)) {
        handleRemoveScenario(scenario);
      } else {
        handleAddScenario(scenario);
      }
    },
    [activeScenarios, handleAddScenario, handleRemoveScenario],
  );

  const handleSaveChanges = useCallback(async () => {
    if (gqlCamera) {
      setLoading(true);
      await updateCamera({
        scenarios: activeScenarios.map((scenario) => scenario.name),
      });
      setLoading(false);
    }

    onClose();
  }, [activeScenarios, gqlCamera, onClose, updateCamera]);

  return (
    <Modal
      centered
      className="bw-modal"
      dialogClassName="modal-50vw"
      contentClassName={theme}
      show={show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera_scenarios.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          {groupScenariosByCategory(customerScenarios).map(
            ({ category, scenarios }) => (
              <Col key={category} md={6} className="mb-3">
                <h3 className="mb-2 weight-600" style={{ fontSize: '1rem' }}>
                  {getScenarioCategoryTitle(category)}
                </h3>
                {scenarios.map((scenario) => (
                  <FormCheck
                    key={scenario.id}
                    className="check-box mb-1"
                    checked={activeScenarios.includes(scenario)}
                    label={
                      <ScenarioLabel
                        scenario={scenario}
                        showTooltip
                        tooltipPlacement="right"
                        cursor="pointer"
                        onClick={() => handleScenarioClick(scenario)}
                      />
                    }
                    onChange={() => handleScenarioClick(scenario)}
                  />
                ))}
              </Col>
            ),
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <BWButton
          title={i18n.t('button.back_to_settings')}
          className="button-with-loader"
          variant="outline"
          type="button"
          disabled={loading}
          onClick={onClose}
        />

        <BWButton
          title={i18n.t('button.save_changes')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={handleSaveChanges}
          loading={loading}
          disabled={loading}
        />
      </Modal.Footer>
    </Modal>
  );
}
