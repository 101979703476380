/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { getScenarioTitle, Scenario } from '../../../lib/features/scenario';
import { BWButton } from '../../elements/BWButton';
import {
  CustomDropdown,
  mapScenarioToDropdownProps,
} from '../../elements/CustomDropdown';

interface Props {
  show: boolean;
  onClose: () => void;
}

export function ResetCameraModal({ show, onClose }: Props) {
  const { theme } = useThemeContext();
  const { customerScenarios } = useScenarioContext();
  const { gqlCamera, resetCamera } = useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedScenario, setSelectedScenario] = useState<Scenario>();

  const handleResetCamera = async () => {
    if (!gqlCamera) return false;

    if (!selectedScenario) {
      toast.error(i18n.t('toast.error.please_select_scenario'));
      return false;
    }

    setLoading(true);
    const success = await resetCamera({
      camera_id: gqlCamera.id,
      scenario_id: selectedScenario.id,
      ip_address: gqlCamera.ip_address,
    });
    setLoading(false);

    return success;
  };

  if (!gqlCamera) return null;

  return (
    <Modal
      centered
      className="custom-modal bw-modal"
      contentClassName={theme}
      show={show}
      onHide={onClose}
      css={css`
        .modal-dialog {
          .modal-content {
            min-height: auto !important;
          }
        }
      `}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.reset_camera.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <p className="mb-1 weight-600">
          {i18n.t('modal.reset_camera.scenario_title')}
        </p>
        <CustomDropdown
          variant="scenario"
          className="rounded-pill me-12"
          menuClassName="h-auto"
          title={
            selectedScenario
              ? getScenarioTitle(selectedScenario)
              : i18n.t('button.select_scenario')
          }
          dropdownData={customerScenarios
            .filter((scenario) => scenario.name === 'road-safety-eq')
            .map(mapScenarioToDropdownProps)}
          onClick={({ scenario }) => {
            if (scenario) {
              setSelectedScenario(scenario);
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <BWButton
          title={i18n.t('button.back_to_settings')}
          className="button-with-loader"
          variant="outline"
          type="button"
          disabled={loading}
          onClick={onClose}
        />

        <BWButton
          title={i18n.t('button.reset')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={handleResetCamera}
          loading={loading}
          disabled={loading || !selectedScenario}
        />
      </Modal.Footer>
    </Modal>
  );
}
